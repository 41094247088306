<template>
    <!--srccomponentstext_contentindexvuehtmlStart-->
    <div id="defaultId1" :style="'justify-content:' + contentAlign" class="     u9f1ef">
        <div id="gfbd43" class="     u2bddc">
            <div id="gb0241" class="     uf72e0">
                <slot id="c6e5f9" name="icon" data="我是数据">
                </slot>
            </div>
            <div id="g247d4" :style="'width:' + titleWidth + ';' +'margin-right:'+ marginRight+';'">
                <div id="g4f627" class="     ue234e">{{label}}</div>
            </div>
        </div>
        <slot id="c2f49f" data="我是数据">
        </slot>
    </div>
    <!--srccomponentstext_contentindexvuehtmlEnd-->

</template>
<script>
    // interfaceCode
    //importStart

    //importEnd

    export default {
        name: "",

        props: {
            // srccomponentstext_contentindexvuePropsStart

            // srccomponentstext_contentindexvuePropsEnd

            contentAlign: {
                type: String,
                default: "flex-start"
            },

            titleWidth: {
                type: String,
                default: ""
            },

            marginRight: {
                type: String,
                default: ""
            },

            label: {
                type: String,
                default: ""
            }
        },

        mounted() {
            // defaultLoad
            // srccomponentstext_contentindexvueMountedStart

            // srccomponentstext_contentindexvueMountedEnd

        },
        components: {},
        data() {
            return {
                // insertData
                // srccomponentstext_contentindexvueDataStart

                // srccomponentstext_contentindexvueDataEnd

            }
        },
        methods: {
            // insertMethod
            // srccomponentstext_contentindexvueMethodStart
            // default
            default () {

            }
            // srccomponentstext_contentindexvueMethodEnd

        },
        computed: {}
    };
</script>
<style scoped>
    .srccomponentstext_contentindexvuecssStart {}

    .u9f1ef {
        display: flex;
        align-items: center;
    }

    .u2bddc {
        display: flex;
        align-items: center;
    }

    .uf72e0 {
        margin-right: 10px;
    }

    .ue234e {
        font-size: 1.42857142857143rem;
        /* font-weight: bold; */
        font-family: 'DMSans Bold';
    }

    .srccomponentstext_contentindexvuecssEnd {}
</style>