<template>
    <!--srccomponentsgui_cardgui_cardvuehtmlStart-->

    <div id="defaultId1" class="     uUIRzUi">
        <div id="g3963c" class="      uae104" v-if="isShowLabel">
            <div id="gb37c4" class="   uWqiWvx">
                <slot id="idTRjK3z" name="left_icon" data="我是数据">
                </slot>
                <span id="idIoM6Nf" class=" uulXpGX detail_title_20">{{label}}
                </span>
            </div>
            <slot id="c3ad37" name="right_view" data="我是数据">
            </slot>
        </div>
        <slot id="c03b8a">
        </slot>
    </div>
    <!--srccomponentsgui_cardgui_cardvuehtmlEnd-->

</template>
<script>
    // interfaceCode
    //importStart

    //importEnd

    export default {
        name: "",

        props: {
            // srccomponentsgui_cardgui_cardvuePropsStart

            // srccomponentsgui_cardgui_cardvuePropsEnd

            isShowLabel: {
                type: Boolean,
                default: true
            },

            label: {
                type: String,
                default: ""
            }
        },

        mounted() {
            // defaultLoad
            // srccomponentsgui_cardgui_cardvueMountedStart

            // srccomponentsgui_cardgui_cardvueMountedEnd

        },
        components: {},
        data() {
            return {
                // insertData
                // srccomponentsgui_cardgui_cardvueDataStart

                // srccomponentsgui_cardgui_cardvueDataEnd

            }
        },
        methods: {
            // insertMethod
            // srccomponentsgui_cardgui_cardvueMethodStart
            // default
            default () {

            }
            // srccomponentsgui_cardgui_cardvueMethodEnd

        },
        computed: {}
    };
</script>
<style>
    .srccomponentsgui_cardgui_cardvuecssStart {}

    .uUIRzUi {
        padding: 1.42rem 2.85rem 0;
        box-shadow: 0px 0.14rem 0.28rem 0px rgba(0, 0, 0, 0.12);
        background-color: #FFFFFF;
        border-radius: 0.28rem;
    }

    .uae104 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.42rem;
        line-height: 2.85rem;
        /* font-size: 2.14em; */
    }

    .uWqiWvx {
        display: flex;
    }

    .uulXpGX {
        /* font-weight: bold; */
        font-family: 'DMSans Bold';
        font-size: 2.142857142857143rem;
    }

    .srccomponentsgui_cardgui_cardvuecssEnd {}
</style>